import React, { useState } from 'react';
import { css } from '@emotion/react';
import ConditionalWrapper from './conditional-wrapper';
import Link from './link';

export default function LinkPanel({ className, hoverColor, hoverBackground, invert, content }) {
  const black = invert ? 'var(--white)' : 'var(--black)';

  return (
    <div
      className={className}
      css={css`
        position: relative;
        display: flex;
        border-top: 4px solid ${black};
        border-bottom: 4px solid ${black};

        @media (max-width: 800px) {
          border-width: 3px;
        }

        @media (max-width: 650px) {
          flex-wrap: wrap;
        }
      `}
    >
      {content.length > 0 &&
        content.map((item, i) => {
          return (
            <div
              key={i}
              css={css`
                flex-basis: 50%;

                @media (max-width: 650px) {
                  flex-basis: 100%;
                }
              `}
            >
              <ConditionalWrapper
                condition={item.url}
                wrapper={(children) => <Link to={item.url}>{children}</Link>}
              >
                <div
                  className="type--underline-inverse type--heading-three"
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 28rem;
                    color: ${black};
                    text-align: center;
                    padding: var(--gutter);
                    border-left: ${i === 1 ? `4px solid ${black}` : 'none'};
                    cursor: pointer;

                    @media (max-width: 800px) {
                      min-height: 19.7rem;
                      padding: var(--gutterHalf);
                      border-left: ${i === 1 ? `3px solid ${black}` : 'none'};
                    }

                    @media (max-width: 650px) {
                      border-top: ${i === 1 ? `3px solid ${black}` : 'none'};
                      border-left: none;
                    }

                    &:hover {
                      color: ${hoverColor ? hoverColor : 'inherit'};
                      background-color: ${hoverBackground ? hoverBackground : 'inherit'};
                    }
                  `}
                  onClick={item.onClick}
                  onMouseOut={item.onMouseOut}
                >
                  {item.text}
                </div>
              </ConditionalWrapper>
            </div>
          );
        })}
    </div>
  );
}
